import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';

import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider as PolarisProvider, Frame } from '@shopify/polaris';
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';
import { ErrorBoundary } from 'react-error-boundary';
import { handleGeneralError } from 'utils/errorHandler';
import ErrorComponent from 'components/ErrorComponent/ErrorComponent';
import ToastNotification from 'components/ToastNotification/ToastNotification';
import { QueryClient, QueryClientProvider } from 'react-query';
import PolarisLink from './PolarisLink';
import AppRoutes from './AppRoutes';
import GoogleTagManager from './GoogleTagManager';
import AppContainer from './containers/AppContainer';
import OAuthRedirect from './auth/OAuthRedirect';
import Install from './install/Install';

import '@shopify/polaris/build/esm/styles.css';
import './style.css';
import SetPlan from './components/SetPlan';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import { getEnvironment, getIntercomAppId, getMixPanelID } from './constants';
import AppBridge, { getPlatform, getShopDomain } from './containers/AppBridge';
import ContextualSaveBar from 'components/ContextualSaveBar';
import mixpanel from 'mixpanel-browser';
import useShop from './hooks/useShop';
import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';

// Add this type declaration at the top of the file, after the imports
declare global {
  interface Window {
    shopify?: {
      config?: {
        version: string;
      };
      toast?: {
        show: (message: string, options?: { duration?: number }) => void;
      };
    };
  }
}

if (getPlatform() === 'Woo') {
  import('./wooStyle.css');
}

const optimizely = createInstance({
  sdkKey: process.env.REACT_APP_OPTIMIZELY_SDK_KEY,
});
const queryClient = new QueryClient();

const shopDomain = getShopDomain();
const env = getEnvironment();
if (
  env === 'development' ||
  env === 'test' ||
  env === 'production' ||
  env === 'staging'
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: env,
    integrations: [
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
    release: process.env.npm_package_version,
    tracesSampleRate: 1.0,
  });

  Sentry.setTag('shop_id', shopDomain);
}

mixpanel.init(getMixPanelID());

const AnalyticsConfiguration: React.FC = ({ children }) => {
  const { data } = useShop();
  const { update: updateIntercom } = useIntercom();

  const accountId = data?.shop?.accountId;
  useEffect(() => {
    if (accountId) {
      console.info(`Identifying user as account ID ${accountId}`);
      mixpanel.identify(accountId);
      mixpanel.people.set_once('Account ID', accountId); // Useful because Mixpanel has a bad habit of using the first distinct ID when we merge. Using ths property we can always get the latest account ID.
      mixpanel.people.set_once({ 'Sign Up Date': new Date() });
      Sentry.setUser({ username: accountId, id: shopDomain });
      updateIntercom({ userId: accountId });
    } else {
      // Either `data` hasn't loaded yet or it has but there's no account ID yet.
      // Either way, temporarily use the shop domain as the user ID.
      // Note for the second case (shop doesn't have an account ID yet), core will eventually fix this for mixpanel
      // by merging the identities when the account ID is created.
      console.info(`Identifying user as shop domain ${shopDomain}`);
      Sentry.setUser({ id: shopDomain });
      mixpanel.identify(shopDomain);
      mixpanel.people.set_once('Shop Domain', shopDomain);
      // For backwards compatibility, intercom is not set in this case
    }
  }, [accountId, updateIntercom]);

  return <>{children}</>;
};

/**
 * On Woo platform, reports back our current path to the parent window.
 */
const WooIframeAdapter: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (getPlatform() !== 'Woo') {
      return;
    }

    const currentPath = location.pathname;

    let search: string = '';

    if (location.search) {
      const params = new URLSearchParams(location.search);
      params.delete('wooSecret');
      params.delete('shop');
      params.delete('platform');
      search = `?${params.toString()}`;
    }

    const route = `${currentPath}${search}`;
    window.parent.postMessage(
      { type: 'pathUpdate', route: route },
      `https://${shopDomain}`
    );
  }, [location]);

  return null;
};

// const AppBridgeScript: React.FC = () => {
//   useEffect(() => {
//     // Create and add the meta tag for API key
//     const metaTag = document.createElement('meta');
//     metaTag.name = 'shopify-api-key';
//     metaTag.content = process.env.REACT_APP_SHOPIFY_API_KEY || '';
//     document.head.appendChild(metaTag);

//     // Create and load the App Bridge script
//     const script = document.createElement('script');
//     script.src = 'https://cdn.shopify.com/shopifycloud/app-bridge.js';
//     // Remove async and defer attributes
//     script.async = false;
//     script.defer = false;

//     // Add load event listener to verify script loading
//     script.onload = () => {
//       // Check if window.shopify exists (this is created by App Bridge)
//       if (window.shopify) {
//         console.log('AppBridgeScript: App Bridge initialized successfully');
//       } else {
//         console.warn(
//           'AppBridgeScript: App Bridge not initialized properly - window.shopify is undefined'
//         );
//       }
//     };

//     // Add error listener
//     script.onerror = (error) => {
//       console.error('AppBridgeScript: Error loading App Bridge script:', error);
//     };

//     // Insert script after the shopify-debug meta tag
//     const head = document.head;
//     const debugMetaTag = document.querySelector('meta[name="shopify-debug"]');
//     if (debugMetaTag && debugMetaTag.nextSibling) {
//       head.insertBefore(script, debugMetaTag.nextSibling);
//     } else {
//       // Fallback to inserting as first child if meta tag isn't found
//       if (head.firstChild) {
//         head.insertBefore(script, head.firstChild);
//       } else {
//         head.appendChild(script);
//       }
//     }

//     return () => {
//       // Cleanup if needed
//       const existingScript = document.querySelector(
//         'script[src="https://cdn.shopify.com/shopifycloud/app-bridge.js"]'
//       );
//       const existingMeta = document.querySelector(
//         'meta[name="shopify-api-key"]'
//       );
//       if (existingScript) {
//         existingScript.remove();
//       }
//       if (existingMeta) {
//         existingMeta.remove();
//       }
//     };
//   }, []);

//   return null;
// };

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GoogleTagManager>
        <IntercomProvider
          appId={getIntercomAppId()}
          autoBoot={true}
          onShow={() => {
            mixpanel.track('Customer Support Opened', {
              platform: getPlatform(),
            });
          }}
          autoBootProps={{
            name: getShopDomain().replace('.myshopify.com', ''),
          }}
        >
          <BrowserRouter
            getUserConfirmation={(message, callback) => {
              callback(window.confirm(message));
            }}
          >
            <OptimizelyProvider
              optimizely={optimizely}
              user={{
                id: getShopDomain(),
                attributes: {
                  shopify_domain: getShopDomain(),
                },
              }}
            >
              <PolarisProvider
                i18n={enTranslations}
                linkComponent={PolarisLink}
              >
                <ErrorBoundary
                  FallbackComponent={ErrorComponent}
                  onError={handleGeneralError}
                >
                  <AnalyticsConfiguration>
                    <Frame>
                      <Switch>
                        <Route exact path="/redirect">
                          <OAuthRedirect />
                        </Route>
                        <Route path="/install">
                          <Install />
                        </Route>
                        <Route path="/">
                          {/* Should be inferring the platform from the window object */}
                          <ContextualSaveBar>
                            <AppBridge>
                              <AppContainer>
                                {/* <AppBridgeScript /> */}
                                <SetPlan />
                                <WooIframeAdapter />
                                <AppRoutes />
                              </AppContainer>
                            </AppBridge>
                          </ContextualSaveBar>
                        </Route>
                      </Switch>
                      <ToastNotification />
                    </Frame>
                  </AnalyticsConfiguration>
                </ErrorBoundary>
              </PolarisProvider>
            </OptimizelyProvider>
          </BrowserRouter>
        </IntercomProvider>
      </GoogleTagManager>
    </QueryClientProvider>
  );
}

export default App;
