import { ReactNode } from 'react';
import { Redirect } from '@shopify/app-bridge/actions';
import React from 'react';
import {
  AppBridgeContext,
  RedirectAdminAction,
  RedirectAdminResource,
  RedirectPassThroughAction,
} from 'containers/AppBridge';
import { useHistory } from 'react-router';
import { useAppBridge as useShopifyAppBridge } from '@shopify/app-bridge-react';
import { getSessionToken as getShopifySessionToken } from '@shopify/app-bridge-utils';

interface ToastOptions {
  message: string;
  duration?: number;
  isError?: boolean;
}

const ShopifyDriver: React.FC<{
  apiKey: string;
  host: string;
  children: ReactNode;
}> = ({ apiKey, host, children }) => {
  const shopifyAppBridge = useShopifyAppBridge();

  const history = useHistory();
  const redirectObject = Redirect.create(shopifyAppBridge);

  const redirect = (
    action: RedirectPassThroughAction | RedirectAdminAction,
    resource: string | RedirectAdminResource
  ) => {
    if (action === 'App') {
      if (typeof resource !== 'string') {
        throw new Error(
          'Resource must be a string for an App or Remote redirect'
        );
      }
      history.push(resource);
    } else if (action === 'Remote') {
      if (typeof resource !== 'string') {
        throw new Error(
          'Resource must be a string for an App or Remote redirect'
        );
      }
      redirectObject.dispatch(Redirect.Action.REMOTE, resource);
    } else {
      if (typeof resource === 'string') {
        throw new Error(`Resource must be of type RedirectAdminResource`);
      }
      switch (resource.type) {
        case 'checkout':
          redirectObject.dispatch(
            Redirect.Action.ADMIN_PATH,
            '/settings/checkout'
          );
          break;
        case 'nft_application':
          redirectObject.dispatch(
            Redirect.Action.ADMIN_PATH,
            `/nft-application?apiKey=${encodeURIComponent(
              resource.apiKey
            )}&callback=${encodeURIComponent(resource.callbackUrl)}`
          );
          break;
        case 'product':
          redirectObject.dispatch(Redirect.Action.ADMIN_SECTION, {
            name: Redirect.ResourceType.Product,
            newContext: resource.newContext,
            resource: {
              id: resource.id,
            },
          });
          break;
        case 'subscription':
          redirectObject.dispatch(
            Redirect.Action.ADMIN_PATH,
            '/settings/billing/subscriptions'
          );
          break;
        case 'shipping':
          redirectObject.dispatch(
            Redirect.Action.ADMIN_PATH,
            '/settings/shipping'
          );
          break;
        default:
          throw new Error('Not implemented yet');
      }
    }
  };

  const showToast = (options: ToastOptions) => {
    // Function to attempt showing the toast
    const attemptShowToast = () => {
      if (window.shopify?.toast) {
        try {
          const duration = options.duration || 3000;
          window.shopify.toast.show(options.message, {
            duration: duration,
          });
        } catch (error) {
          console.error('ShopifyDriver: Error showing toast:', error);
        }
      } else {
        console.warn(
          'ShopifyDriver: App Bridge Toast not available - window.shopify.toast is undefined'
        );
        // Retry after a short delay if App Bridge isn't loaded yet
        setTimeout(attemptShowToast, 100);
      }
    };

    // Initial attempt
    attemptShowToast();
  };

  const getSessionToken = async () => {
    return getShopifySessionToken(shopifyAppBridge);
  };

  const openInNewTab = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <AppBridgeContext.Provider
      value={{
        host: host,
        apiKey: apiKey,
        redirect,
        showToast,
        getSessionToken,
        platform: 'Shopify',
        openInNewTab,
      }}
    >
      {children}
    </AppBridgeContext.Provider>
  );
};

export default ShopifyDriver;
