import React from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs } from '@shopify/polaris';
import t from 'lib/translation';
import { Layout } from '@shopify/polaris';
import { SETTINGS_PATH } from '../AppRoutes';
import { useAppBridge } from '../containers/AppBridge';
import { isPageDisabled } from '../platform/platform';

const defaultTabs = [
  {
    id: 'dashboard',
    content: t('header.tabs.1'),
    accessibilityLabel: t('header.tabs.1'),
    pathname: '/',
    regcheck: [/^\/$/],
  },
  {
    id: 'NFTs',
    content: t('header.tabs.2'),
    accessibilityLabel: t('header.tabs.2'),
    pathname: '/drops/',
    regcheck: [/^\/drops\//, /^\/product-metadata\/.*\/nft\/?$/],
  },
  {
    id: 'tokengates',
    content: t('header.tabs.7'),
    accessibilityLabel: t('header.tabs.7'),
    pathname: '/tokengates/',
    regcheck: [/^\/tokengates\//],
  },
  {
    id: 'certificates',
    content: t('header.tabs.9'),
    accessibilityLabel: t('header.tabs.9'),
    pathname: '/certificate/',
    regcheck: [/^\/certificate\//, /^\/product-metadata\//],
  },
  {
    id: 'prints',
    content: t('header.tabs.11'),
    accessibilityLabel: t('header.tabs.11'),
    pathname: '/prints/',
    regcheck: [/^\/prints\//],
  },
  {
    id: 'customization',
    content: t('header.tabs.10'),
    accessibilityLabel: t('header.tabs.10'),
    pathname: '/customizations/',
    regcheck: [/^\/customizations\//],
  },
  {
    id: 'settings',
    content: t('header.tabs.4'),
    accessibilityLabel: t('header.tabs.4'),
    pathname: SETTINGS_PATH + '/',
    regcheck: [/^\/settings\//, /^\/creators\/$/, /^\/plan\/$/],
  },
  {
    id: 'reports',
    content: t('header.tabs.12'),
    pathname: '/reports/',
    regcheck: [/^\/reports\//],
  },
];

type HeaderProps = {
  pathname: string;
};

const Header = ({ pathname }: HeaderProps) => {
  const { platform } = useAppBridge();

  let filteredTabs = defaultTabs;

  if (
    isPageDisabled('tokengates', platform) ||
    isPageDisabled('prints', platform)
  ) {
    filteredTabs = filteredTabs.filter(
      (tab) => tab.id !== 'tokengates' && tab.id !== 'prints'
    );
  }

  const history = useHistory();
  const compare = pathname.endsWith('/') ? pathname : `${pathname}/`;

  const matchingTab = filteredTabs.find((tab) =>
    tab.regcheck.some((reg) => reg.test(compare))
  );

  const selected = matchingTab ? filteredTabs.indexOf(matchingTab) : 0;

  const handleTabChange = (selectedTabIndex: number) => {
    history.push(filteredTabs[selectedTabIndex].pathname);
  };

  return (
    <Layout>
      <Layout.Section fullWidth>
        <div className="tabsWrapper">
          <Tabs
            tabs={filteredTabs}
            selected={selected}
            onSelect={handleTabChange}
          />
        </div>
      </Layout.Section>
    </Layout>
  );
};

export default Header;
