import { PlanType } from '../../utils/plans';

export enum Frequency {
  MONTH = 'MONTH',
}

export interface Plan {
  title: string;
  price: { [key in Frequency]: string };
  recommended: boolean;
  features: string[];
  charges: string;
}

export type AllPlansIncludingLegacy =
  | PlanType.INDIVIDUAL_STARTER
  | PlanType.INDIVIDUAL_PREMIUM
  | PlanType.INDIVIDUAL_PRO
  | PlanType.BUSINESS_STARTER
  | PlanType.BUSINESS_PREMIUM
  | PlanType.BUSINESS_PRO
  | PlanType.GROW
  | PlanType.PRO
  | PlanType.PAY_AS_YOU_GO;
export const NEW_PLANS = [PlanType.PAY_AS_YOU_GO, PlanType.GROW, PlanType.PRO];

const plans: Record<AllPlansIncludingLegacy, Plan> = {
  // These only exist to show the plan card for legacy cards.
  // {{{
  INDIVIDUAL_STARTER: {
    title: 'Legacy Individual Starter',
    price: {
      MONTH: '$9.99/month',
    },
    charges:
      'Fixed fee per COA, transaction fee per NFT and tokengated print sale',
    recommended: false,
    features: [
      '20 NFTs and COAs/mo',
      '1 custom contracts',
      'Ethereum & Polygon',
      'Unlimited tokengates',
      'Supports multiple languages',
      'Online support',
      '$0.25 per COA',
      '5% fee /$0.25 minimum per NFT sale',
      '7% fee per print sale',
      '',
    ],
  },
  INDIVIDUAL_PREMIUM: {
    title: 'Legacy Individual Premium',
    price: {
      MONTH: '$19.99/month',
    },
    charges:
      'Fixed fee per COA, transaction fee per NFT and tokengated print sale',
    recommended: true,
    features: [
      '120 NFTs & certificates /mo',
      '2 custom contracts',
      'Ethereum & Polygon',
      'Unlimited tokengates',
      'Supports multiple languages',
      'Add your branding',
      '20-minute onboarding call',
      '$0.25 per COA',
      '5% fee /$0.25 minimum per NFT sale',
      '7% fee per print sale',
    ],
  },
  INDIVIDUAL_PRO: {
    title: 'Legacy Individual Pro',
    price: {
      MONTH: '$49.99/month',
    },
    charges:
      'Fixed fee per COA, transaction fee per NFT and tokengated print sale',
    recommended: false,
    features: [
      '1000 NFTs and COAs/mo',
      '5 custom contracts',
      'Ethereum & Polygon',
      'Unlimited tokengates',
      'Supports multiple languages',
      'Add your branding',
      'Dedicated account manager',
      '$0.25 per COA',
      '5% fee /$0.25 minimum per NFT sale',
      '7% fee per print sale',
    ],
  },
  BUSINESS_STARTER: {
    title: 'Legacy Business Starter',
    price: {
      MONTH: '$29.99/month',
    },
    charges:
      'Fixed fee per COA, transaction fee per NFT and tokengated print sale',
    recommended: false,
    features: [
      '5 represented creators',
      '100 NFTs and COAs/mo',
      '5 custom contracts',
      'Ethereum & Polygon',
      'Unlimited tokengates',
      'Supports multiple languages',
      'Online support',
      '$0.25 per COA',
      '5% fee /$0.25 minimum per NFT sale',
      '7% fee per print sale',
    ],
  },
  BUSINESS_PREMIUM: {
    title: 'Legacy Business Premium',
    price: {
      MONTH: '$59.99/month',
    },
    charges:
      'Fixed fee per COA, transaction fee per NFT and tokengated print sale',
    recommended: true,
    features: [
      '10 represented creators',
      '500 NFTs and COAs/mo',
      '10 custom contracts',
      'Ethereum & Polygon',
      'Unlimited tokengates',
      'Supports multiple languages',
      'Add your branding',
      '20-minute onboarding call',
      '$0.25 per COA',
      '5% fee /$0.25 minimum per NFT sale',
      '7% fee per print sale',
    ],
  },
  BUSINESS_PRO: {
    title: 'Legacy Business Pro',
    price: {
      MONTH: '$199.99/month',
    },
    charges:
      'Fixed fee per COA, transaction fee per NFT and tokengated print sale',
    recommended: false,
    features: [
      '50 represented creators',
      '5000 NFTs and COAs/mo',
      '50 custom contracts',
      'Ethereum & Polygon',
      'Unlimited tokengates',
      'Supports multiple languages',
      'Add your branding',
      'Dedicated account manager',
      '$0.25 per COA',
      '5% fee /$0.25 minimum per NFT sale',
      '7% fee per print sale',
    ],
  },
  // }}}

  GROW: {
    title: 'Growth',
    price: {
      MONTH: '$29.99/month',
    },
    charges:
      'Fixed fee per COA, transaction fee per NFT and tokengated print sale',
    recommended: true,
    features: [
      '1 verified Creator',
      '1,000 COAs or NFTs per month',
      'Ethereum, Base, Polygon',
      '50 contracts & tokengates',
      'Add your branding/ Online support',
      '$0.25 per COA',
      '5% fee /$0.25 minimum per NFT sale',
      '7% fee per print sale',
    ],
  },
  PRO: {
    title: 'Pro',
    price: {
      MONTH: '$99.99/month',
    },
    charges:
      'Fixed fee per COA, transaction fee per NFT and tokengated print sale',
    recommended: false,
    features: [
      'Represent creators',
      'Unlimited COAs/NFTs',
      'Ethereum, Base, Polygon',
      'Unlimited contracts & tokengates',
      'Add your branding/ Online support',
      '$0.25 per COA',
      '5% fee /$0.25 minimum per NFT sale',
      '7% fee per print sale',
    ],
  },
  PAY_AS_YOU_GO: {
    title: 'Pay as you sell',
    price: {
      MONTH: 'Free',
    },
    charges: 'Usage fee per NFT, COA and tokengated print sale',
    recommended: false,
    features: [
      '1 verified Creator',
      'Mint on Ethereum, Base and Polygon',
      '50 custom contracts',
      '0.5% fee, $0.50 min per COA',
      '7.5% fee, $0.75 min per NFT sale',
      '10% fee, per print sale',
    ],
  },
};

export default plans;
